// @import '@angular/material/prebuilt-themes/purple-green.css';
@use '@angular/material' as mat;
@import "~@fortawesome/fontawesome-free/css/all.css";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
// @import '@fuse/scss/core';
// Perfect scrollbar
// @import '~perfect-scrollbar/css/perfect-scrollbar';
// @import "@fuse/scss/partials/scrollbars";

$wonda-blue: #45a6b9;
$wonda-dark-blue: #0097a7;
$wonda-light-red: #E57372;
$wonda-light-grey: #424242;
$wonda-blue-map: (
    main: $wonda-blue,
    100: $wonda-blue, // For slide toggle,
    200:$wonda-blue,
    700: $wonda-dark-blue,
    contrast : (
      main: white,
      100: white,
      200: white,
      700: white
    )
  );
$wonda-blue-palette: mat.define-palette($wonda-blue-map, 200);
$wonda-app-accent:  mat.define-palette(mat.$red-palette, 300);
$wonda-red: mat.get-color-from-palette($wonda-app-accent, 300);
// The warn palette is optional (defaults to red).
$wonda-app-warn: mat.define-palette($wonda-blue-map, 200);

$primary-color: map-get($wonda-blue-palette, 200);

$navbar-height: 45px;
$left-bar-width: 15em;
$right-bar-width: 320px;
$button-row-height: 48px;

$app-light-background: #EEEEEE;
$app-background: #303030;
$wvr-darkest-grey: #232323;
$card-background: $wonda-light-grey;
$wvr-dark-grey: #494949;
$title-background: black;
$border: black;
$wvr-light-grey: #bababa;
$wvr-medium-light-grey: #858484;
$wvr-extra-light-grey: #d7d7d7;
$wvr-lightest-grey: #EBEBEBEB;


// mixin name will be used in main style.scss
@mixin learnspace-theme($wonda-app-light-theme) {
  
  // retrieve variables from theme 
  // (all possible variables, use only what you really need)
  $primary: map-get($wonda-app-light-theme, primary);
  $accent: map-get($wonda-app-light-theme, accent);
  $warn: map-get($wonda-app-light-theme, accent);
  $foreground: map-get($wonda-app-light-theme, foreground);
  $background: map-get($wonda-app-light-theme, background);
  
  // all of these variables contain many additional variables

  learnspace {

    div {
    
      // retrieve particular color from variable, rg: text, base, divider, ...
      color: mat.get-color-from-palette($foreground, text);
      background-color: mat.get-color-from-palette($background, card);
      
      // or use "default" subvariable by default, oh the puns :)
      border: 1px solid mat.get-color-from-palette($accent);
    }
  }
}

// .learnspace-theme {
//   @include learnspace-theme($wonda-app-light-theme);
// }


// Create the theme object (a Sass map containing all of the palettes).
$wonda-app-theme: mat.define-dark-theme($wonda-blue-palette, $wonda-app-accent, $wonda-app-warn);

$wonda-app-light-primary: mat.define-palette($wonda-blue-palette, 200);
$wonda-app-light-accent:  mat.define-palette(mat.$grey-palette, 800);
// The warn palette is optional (defaults to red).
$wonda-app-light-warn: mat.define-palette(mat.$grey-palette, 800);
$dark-background: map-get($wonda-app-theme, background);
$background: map_merge($dark-background, (card: $app-background, dialog: $app-background)); 
$wonda-app-theme: map_merge($wonda-app-theme, (background: $background));
// @debug $wonda-app-theme
$wonda-app-light-theme: mat.define-light-theme($wonda-app-light-primary, $wonda-app-light-accent, $wonda-app-light-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

.editor-theme{

  @include mat.all-legacy-component-themes($wonda-app-theme);
  textarea{
    background-color: $app-background;
    color: white;   
  }
  .close{
    color: white;
    text-shadow: none;
  }

  .thumbnail-background {
    background-color: $wvr-dark-grey;
  }
}

.learnspace-theme{
  @include mat.all-legacy-component-themes($wonda-app-light-theme);
  textarea{
    background-color: white;
    color: black;
  }
  .close{
    color: black;
    text-shadow: none;
  }
  .mat-checkbox{
    color: black !important;
  }

  .mat-raised-button.mat-primary {
    color: white;
  }

  .mat-stroked-button.mat-primary {
    border-color: $wonda-blue;
  }

  .thumbnail-background {
    background-color: $wvr-extra-light-grey;
  }
}

// :export{
//     $primary: $wonda-app-primary;
// }

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.

// Each level has a font-size, line-height and font-weight. The available levels are:
// display-4, display-3, display-2 and display-1 - Large, one-off headers, usually at the top of the page (e.g. a hero header).
// headline - Section heading corresponding to the <h1> tag.
// title - Section heading corresponding to the <h2> tag.
// subheading-2 - Section heading corresponding to the <h3> tag.
// subheading-1 - Section heading corresponding to the <h4> tag.
// body-1 - Base body text.
// body-2 - Bolder body text.
// caption - Smaller body and hint text.
// button - Buttons and anchors.
// input - Form input fields.

$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Raleway, sans-serif',
  $subheading-2: mat.define-typography-level(20px, 1.875, 500),
  $subheading-1: mat.define-typography-level(14px, 1.875, 500),
  $body-1: mat.define-typography-level(14px, 1.25, 500),
  $body-2: mat.define-typography-level(14, 1, 800),
  $input: mat.define-typography-level(1em, 1.15, 400),
  $caption: mat.define-typography-level(12px, 1.5, 300),
  $button: mat.define-typography-level(12px, 1.2, 400),
  $headline: mat.define-typography-level(40px, 1.2, 200),
  $display-1: mat.define-typography-level(40px, 1.2, 200)
);

// $config: mat-typography-config();

// // Custom header that uses only the Material `font-size` and `font-family`.
// .mat-icon {
//   font-size: mat-font-size($config, body-1);
//   font-family: mat-font-family($config);
// }

// // Custom title that uses all of the typography styles from the `title` level.
// .mat-icon {
//   @include mat-typography-level-to-styles($config, title);
// }

@include mat.all-legacy-component-typographies($custom-typography);

@include mat.icon-typography($custom-typography);

@include mat.legacy-typography-hierarchy($custom-typography);

mat-icon{
  font-size: 14px; 
  overflow: visible;
}

.mat-button-toggle-label-content {
  padding: 0 !important;
  cursor: pointer;
  margin: 0px !important;
}

.mat-dialog-container{
  padding: 0px !important;
}


//prevent bottom space
.ps__rail-y{
  // z-index: 2 !important;
  display:none !important;
}

content{
  z-index:0;
}
content > *:not(router-outlet) {
  display: flex;
  flex-direction: column;

}
/// 


body,
html{
   overflow: hidden;
   margin: 0;
   padding: 0;
}
// .dropdown-menu{
//   background-color: #303030;
//   color: white !important; 
// }


//fixes scrolling bugs on left nav mat-expansion panel
.mat-expansion-panel-content.mat-expanded {
  overflow: hidden;
}

.mat-menu-content{
  background-color: white !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
  .mat-checkbox-label{
    line-height: 24px;
  }
}

.disabled {
  &:active { //this part is really important it allow hover but not click trigger
    pointer-events:none; 
  }
}

.plan-limit-disabled{
  opacity: 0.3 !important;
}

button {
  outline: none;
  &:focus {
    outline: none;
  }
}

.error-msg {
  color: red;
}

.selected-element {
  background-color: $wvr-darkest-grey !important;
  opacity: 1 !important;

  ::ng-deep .sceneTitle {
    font-weight: 600;
  }
  ::ng-deep img {
    border: solid 1px $wonda-light-red;
  }
}

@keyframes fadein {
  0%   {
    opacity: 0;
 }
 100% { 
   opacity: 1;
 }
}

@keyframes fadeout {
  0%   {
    opacity: 1;
 }
 100% { 
   opacity: 0;
 }
}
// // Import Fuse core library
// @import "@fuse/scss/core";

// // -----------------------------------------------------------------------------------------------------
// // @ Multiple material themes example
// // -----------------------------------------------------------------------------------------------------

// // Define the Material palettes
// $yellow-theme-primary: mat-palette($mat-fusedark);
// $yellow-theme-accent: mat-palette($mat-yellow, 600, 400, 700);
// $yellow-theme-warn: mat-palette($mat-red);

// // Create the Material theme object
// $yellow-theme: mat-light-theme($yellow-theme-primary, $yellow-theme-accent, $yellow-theme-warn);

// // Add ".yellow-theme" class to the body to activate this theme
// .yellow-theme {

//     // Generate the Angular Material theme
//     @include angular-material-theme($yellow-theme);

//     // Generate the fuse color classes with the new colors...
//     @include generateFuseColorClasses($yellow-theme-primary, $yellow-theme-accent, $yellow-theme-warn);
// }

// Scrollbar style
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
::-webkit-scrollbar {
  background: transparent;
  width: 7px;
}
*{
  scrollbar-color: grey transparent;
  scrollbar-width: thin;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: unset !important;
}

.floating-cell {
  overflow: hidden;
  margin: 8px;
  border-radius: 5px;
  background-color: $app-background !important;
}

.help-link{
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  height: 18px;
}

.single-line-tooltip {
  white-space: nowrap;
  max-width: none !important;
}

::ng-deep .ngx-contextmenu li {
  display: block;
  text-align: left;
  font-size: 12px;
}

::ng-deep .ngx-contextmenu button {
  background-color: $app-background;
  border: none;
  color:white;
  display: block;
  padding: 0.5em 1em;
  width: 100%;
  &:hover {
    background-color: $wvr-dark-grey;
  }
}

::ng-deep .ngx-contextmenu li.disabled button{
  color: darkgrey;
}